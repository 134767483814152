import React, { useState, useEffect } from "react"
import { useInView } from 'react-intersection-observer'
import * as styles from "./revealable_comp.module.css"


const RevealableComp = ({children, threshold, style}) => {
	const [show, setShow] = useState(0);
	const [transition, setTransition] = useState(0);
	const reveal = show ? transition ? [styles.box, styles.box_transition].join(" ") : styles.box :
	 					  transition ? [styles.box_hidden, styles.box_transition].join(" ") : styles.box_hidden;
	const { ref, inView } = useInView({
		threshold: threshold,
		initialInView: !show
	});

	useEffect( () => {
		if( !inView ) {
			setShow(1);
			setTransition(1);
		} else {
			setShow(0);
			setTransition(1);
		}
	}, [inView])

	return (
		<>
			<div ref={ref} className={styles.waypoint}></div>
			<div className={reveal} style={{...style}}>
				{children}
			</div>
		</>
	)
}

export default RevealableComp
