import React from "react"

export const arrow_home =
	<svg xmlns="http://www.w3.org/2000/svg" width="48" height="88" viewBox="0 0 48 88">
		<g id="Raggruppa_5" data-name="Raggruppa 5" transform="translate(-936 -866)" style={{fill: "#fff"}}>
		<path id="Poligono_1" data-name="Poligono 1" d="M24,0,48,60H0Z" transform="translate(984 954) rotate(180)"/>
		<circle id="Ellisse_1" data-name="Ellisse 1" cx="10" cy="10" r="10" transform="translate(950 866)"/>
		</g>
	</svg>

export const icon_be_black =
	<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1250 1250">
		<g id="XMLID_00000150076296628903341750000010497449477669047434_">
			<path id="XMLID_00000040549880310371510590000002528255867519914154_" d="M625,1224.4L625,1224.4c-331.1,0-599.4-268.4-599.4-599.4   v0C25.6,293.9,293.9,25.6,625,25.6l0,0c331.1,0,599.4,268.4,599.4,599.4v0C1224.4,956.1,956.1,1224.4,625,1224.4z"/>
		</g>
		<g id="XMLID_00000107556906451587039900000005407586221126252167_">
			<path id="XMLID_00000127765783183917458330000007969839169837780146_" style={{fill:"#FFFFFF"}} d="M524.1,849.2c20.5-4.3,38.4-12.2,53.5-23.7   c13.4-10.1,24.6-22.5,33.5-37.2c14-22.3,21.1-47.4,21.1-75.5c0-27.2-6.2-50.3-18.4-69.3c-12.3-19-30.5-33-54.7-41.8   c15.9-8.1,27.9-17.1,36.1-27c14.6-17.5,21.9-40.8,21.9-69.7c0-28-7.3-52.1-21.7-72.2c-24.1-32.7-64.9-49.5-122.6-50.4H244v473.2   h213.2C481.3,855.7,503.6,853.5,524.1,849.2z M338.4,464.6h103c22.7,0,41.3,2.5,55.9,7.4c17,7,25.4,21.6,25.4,43.6   c0,19.9-6.4,33.8-19.2,41.6c-12.8,7.8-29.5,11.7-50,11.7h-115V464.6z M338.4,773.5V647.3h116.5c20.4,0.2,36.1,2.9,47.3,8   c20.1,9.2,30.1,26.1,30.1,50.7c0,29.1-10.4,48.8-31.1,59.1c-11.4,5.6-27.4,8.3-47.9,8.3H338.4z"/>
			<g id="XMLID_00000040544127618809985240000005131616003244425869_">
				<path id="XMLID_00000061454027474189202700000012111411163317121981_" style={{fill:"#FFFFFF"}} d="M712.3,824.5    c35.6,28.7,76.7,43.1,123.2,43.1c56.4,0,100.3-17,131.6-51.1c20.2-21.4,31.5-42.5,33.9-63.2h-93.4c-5.4,10.3-11.7,18.3-18.8,24.1    c-13,10.7-30,16.1-50.8,16.1c-19.7,0-36.5-4.4-50.4-13.2c-23-14.1-35.2-38.7-36.6-73.9h254.9c0.5-30.2-0.6-53.4-3-69.4    c-4.2-27.4-13.5-51.6-27.9-72.3c-15.9-23.6-36-40.8-60.5-51.8c-24.4-10.9-51.8-16.4-82.3-16.4c-51.3,0-93,16.2-125.1,48.5    c-32.1,32.3-48.2,78.8-48.2,139.4C658.9,749.1,676.7,795.8,712.3,824.5z M777.7,591.9c13-13.3,31.1-20,54.6-20    c21.6,0,39.7,6.3,54.3,18.8c14.6,12.6,22.7,31,24.4,55.3H753.3C756.6,623.2,764.7,605.2,777.7,591.9z"/>

				<rect id="XMLID_00000089533620064388981080000009177489577433395115_" x="723.5" y="409.7" style={{fill:"#FFFFFF"}} width="205.6" height="47.8"/>
			</g>
		</g>
	</svg>

export const icon_ig_black =
	<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1250 1250">
		<g id="XMLID_00000163060517181912209270000007238869923816860091_">
			<path id="XMLID_00000118384264707929471570000001240435310735563957_" d="M625,1224.4L625,1224.4c-331.1,0-599.4-268.4-599.4-599.4   v0C25.6,293.9,293.9,25.6,625,25.6l0,0c331.1,0,599.4,268.4,599.4,599.4v0C1224.4,956.1,956.1,1224.4,625,1224.4z"/>
			<g id="XMLID_00000078024527254504038320000016746249537918133180_">
				<path id="XMLID_00000025428757184505681060000004228111908710497211_" style={{fill:"#FFFFFF"}} d="M625,355.5c87.8,0,98.2,0.3,132.8,1.9    c32,1.5,49.4,6.8,61,11.3c15.3,6,26.3,13.1,37.8,24.6c11.5,11.5,18.6,22.4,24.6,37.8c4.5,11.6,9.9,29,11.3,61    c1.6,34.7,1.9,45.1,1.9,132.8s-0.3,98.2-1.9,132.8c-1.5,32.1-6.8,49.5-11.3,61c-6,15.3-13.1,26.3-24.6,37.8    c-11.5,11.5-22.4,18.6-37.8,24.6c-11.6,4.5-29,9.9-61,11.3c-34.7,1.6-45,1.9-132.8,1.9c-87.8,0-98.2-0.3-132.8-1.9    c-32-1.5-49.4-6.8-61-11.3c-15.3-6-26.3-13.1-37.8-24.6c-11.5-11.5-18.6-22.4-24.6-37.8c-4.5-11.6-9.9-29-11.3-61    c-1.6-34.7-1.9-45-1.9-132.8s0.3-98.2,1.9-132.8c1.5-32.1,6.8-49.4,11.3-61c6-15.3,13.1-26.3,24.6-37.8    c11.5-11.5,22.4-18.6,37.8-24.6c11.6-4.5,29-9.9,61-11.3C526.8,355.9,537.2,355.5,625,355.5 M625,296.3c-89.3,0-100.5,0.4-135.5,2    c-35,1.6-58.9,7.2-79.8,15.3c-21.6,8.4-39.9,19.6-58.2,37.9c-18.3,18.3-29.5,36.6-37.9,58.2c-8.1,20.9-13.7,44.8-15.3,79.8    c-1.6,35.1-2,46.3-2,135.5c0,89.3,0.4,100.5,2,135.5c1.6,35,7.2,58.9,15.3,79.8c8.4,21.6,19.6,39.9,37.9,58.2    c18.3,18.3,36.6,29.5,58.2,37.9c20.9,8.1,44.8,13.7,79.8,15.3c35.1,1.6,46.3,2,135.5,2c89.3,0,100.5-0.4,135.5-2    c35-1.6,58.9-7.2,79.8-15.3c21.6-8.4,39.9-19.6,58.2-37.9c18.3-18.3,29.5-36.6,37.9-58.2c8.1-20.9,13.7-44.8,15.3-79.8    c1.6-35.1,2-46.3,2-135.5c0-89.3-0.4-100.5-2-135.5c-1.6-35-7.2-58.9-15.3-79.8c-8.4-21.6-19.6-39.9-37.9-58.2    c-18.3-18.3-36.6-29.5-58.2-37.9c-20.9-8.1-44.8-13.7-79.8-15.3C725.5,296.7,714.3,296.3,625,296.3"/>
				<path id="XMLID_00000043427451444814000480000011398783527827184060_" style={{fill:"#FFFFFF"}} d="M625,456.2c-93.2,0-168.8,75.6-168.8,168.8    c0,93.2,75.6,168.8,168.8,168.8c93.2,0,168.8-75.6,168.8-168.8C793.8,531.8,718.2,456.2,625,456.2 M625,734.6    c-60.5,0-109.6-49.1-109.6-109.6c0-60.5,49-109.6,109.6-109.6S734.6,564.5,734.6,625C734.6,685.5,685.5,734.6,625,734.6"/>
				<path id="XMLID_00000059286316903318221340000007494781326527122866_" style={{fill:"#FFFFFF"}} d="M839.9,449.5c0,21.8-17.7,39.4-39.4,39.4    c-21.8,0-39.4-17.7-39.4-39.4c0-21.8,17.7-39.4,39.4-39.4C822.2,410.1,839.9,427.8,839.9,449.5"/>
			</g>
		</g>
	</svg>

export const icon_fb_black =
	<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1250 1250">
		<g id="XMLID_00000132070215972728480690000004809190592281591225_">
			<path id="XMLID_00000139990108069179386070000016583617542804460426_" d="M625,1224.4L625,1224.4c-331.1,0-599.4-268.4-599.4-599.4   v0C25.6,293.9,293.9,25.6,625,25.6l0,0c331.1,0,599.4,268.4,599.4,599.4v0C1224.4,956.1,956.1,1224.4,625,1224.4z"/>
		</g>
		<path id="XMLID_00000031188977528008955320000010031660757767736215_" style={{fill:"#FFFFFF"}} d="M532.2,992.9h148.2V621.9h103.4l11-124.2  H680.4c0,0,0-46.4,0-70.8c0-29.3,5.9-40.9,34.2-40.9c22.8,0,80.2,0,80.2,0V257.1c0,0-84.5,0-102.6,0c-110.3,0-160,48.6-160,141.5  c0,81,0,99.1,0,99.1h-77.1v125.8h77.1V992.9z"/>
	</svg>

export const icon_in_black =
	<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1250 1250">
		<g id="XMLID_00000037657262136495345740000012336437539550246333_">
			<path id="XMLID_00000114757908298651793590000011200726520511885976_" d="M625,1224.4L625,1224.4c-331.1,0-599.4-268.4-599.4-599.4   v0C25.6,293.9,293.9,25.6,625,25.6l0,0c331.1,0,599.4,268.4,599.4,599.4v0C1224.4,956.1,956.1,1224.4,625,1224.4z"/>
		</g>
		<g id="XMLID_00000099642618138637948410000005901749996817188278_">
			<g id="XMLID_00000155148277997996578470000010123590929696416438_">

					<rect id="XMLID_00000085949030131908996380000017916236215261494203_" x="308" y="514.9" style={{fill:"#FFFFFF"}} width="136.3" height="440.4"/>
				<path id="XMLID_00000175322721693257719800000003398468393152706739_" style={{fill:"#FFFFFF"}} d="M375.5,457.2c44.5,0,80.6-36.4,80.6-81.3    s-36.1-81.2-80.6-81.2c-44.5,0-80.6,36.4-80.6,81.2S330.9,457.2,375.5,457.2z"/>
			</g>
			<path id="XMLID_00000000215602427135253330000002748613525622756495_" style={{fill:"#FFFFFF"}} d="M662.2,724.1c0-61.9,28.5-98.8,83.1-98.8   c50.1,0,74.2,35.4,74.2,98.8c0,63.4,0,231.2,0,231.2h135.7c0,0,0-160.9,0-278.8c0-118-66.9-175-160.2-175   c-93.4,0-132.7,72.8-132.7,72.8v-59.3H531.4v440.4h130.8C662.2,955.3,662.2,792,662.2,724.1z"/>
		</g>
	</svg>

export const icon_mail_black =
	<svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1250 1250">
		<g id="XMLID_1683_">
			<path id="XMLID_1692_" d="M625,1224.4L625,1224.4c-331.1,0-599.4-268.4-599.4-599.4v0C25.6,293.9,293.9,25.6,625,25.6l0,0   c331.1,0,599.4,268.4,599.4,599.4v0C1224.4,956.1,956.1,1224.4,625,1224.4z"/>
		</g>
		<path style={{fill:"none", stroke:"#FFFFFF", strokeWidth:"38", strokeMiterlimit:"10"}} d="M974.5,937h-699c-41.6,0-75.2-33.7-75.2-75.2V388.3c0-41.6,33.7-75.2,75.2-75.2h699c41.6,0,75.2,33.7,75.2,75.2  v473.5C1049.7,903.3,1016,937,974.5,937z"/>
		<polyline style={{fill:"none", stroke:"#FFFFFF", strokeWidth:"38", strokeMiterlimit:"10"}} points="200.3,388.3 625,625 1049.7,388.3 "/>
	</svg>

export const icon_be_white =
	<svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1250 1250">
		<path style={{fill:"#fff"}} d="M503.45,557.26q19.24-11.71,19.25-41.58,0-33.07-25.43-43.65-21.94-7.41-55.93-7.4h-103V569h115Q484.24,569,503.45,557.26Z"/>
		<path style={{fill:"#fff"}} d="M502.17,655.34c-11.24-5.14-27-7.81-47.32-8H338.38V773.48H453.3q30.75,0,47.9-8.34,31.11-15.43,31.11-59.09Q532.31,669.14,502.17,655.34Z"/>
		<path style={{fill:"#fff"}} d="M625,25.55C293.93,25.55,25.55,293.93,25.55,625h0c0,331.07,268.38,599.45,599.45,599.45S1224.45,956.07,1224.45,625h0C1224.45,293.93,956.07,25.55,625,25.55Zm98.47,384.1H929.11v47.76H723.47ZM611.1,788.27a131.37,131.37,0,0,1-33.51,37.22q-22.67,17.35-53.48,23.74a327.13,327.13,0,0,1-66.87,6.43H244V382.45H472.72q86.53,1.29,122.63,50.39,21.72,30.18,21.74,72.23,0,43.35-21.92,69.67-12.28,14.79-36.11,27,36.24,13.18,54.68,41.76t18.42,69.32Q632.16,754.89,611.1,788.27Zm394.8-81.92H751q2.13,52.68,36.61,73.86,20.94,13.17,50.42,13.17,31.26,0,50.8-16.07,10.7-8.65,18.85-24h93.42q-3.71,31.1-33.95,63.24-47,51.07-131.64,51-69.84,0-123.25-43.07T658.91,684.37q0-90.92,48.21-139.42t125.11-48.52q45.72,0,82.29,16.38T975,564.58q21.5,31.17,27.88,72.34Q1006.57,661,1005.9,706.35Z"/>
		<path style={{fill:"#fff"}} d="M886.56,590.72q-21.88-18.83-54.3-18.84-35.19,0-54.6,20T753.29,646H910.93Q908.44,609.62,886.56,590.72Z"/>
	</svg>

export const icon_ig_white =
	<svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1250 1250">
		<path style={{fill:"#fff"}} d="M625,515.44A109.56,109.56,0,1,0,734.56,625,109.56,109.56,0,0,0,625,515.44Z"/>
		<path style={{fill:"#fff"}} d="M881.22,431.15a101.88,101.88,0,0,0-24.58-37.79c-11.5-11.5-22.45-18.61-37.79-24.58-11.59-4.5-29-9.86-61-11.32-34.65-1.58-45-1.92-132.81-1.92s-98.16.34-132.81,1.92c-32.05,1.46-49.45,6.82-61,11.32-15.35,6-26.29,13.08-37.8,24.58a102,102,0,0,0-24.58,37.79c-4.5,11.59-9.86,29-11.32,61-1.58,34.66-1.91,45.05-1.91,132.81s.33,98.16,1.91,132.82c1.46,32,6.82,49.45,11.32,61a101.93,101.93,0,0,0,24.58,37.79c11.51,11.5,22.45,18.62,37.8,24.59,11.58,4.5,29,9.86,61,11.32,34.65,1.57,45,1.91,132.81,1.91s98.16-.34,132.81-1.91c32.05-1.46,49.45-6.82,61-11.32,15.34-6,26.29-13.09,37.79-24.59s18.62-22.45,24.58-37.79c4.51-11.58,9.86-29,11.32-61,1.58-34.66,1.92-45.05,1.92-132.82s-.34-98.15-1.92-132.81C891.08,460.14,885.73,442.74,881.22,431.15ZM625,793.78A168.78,168.78,0,1,1,793.78,625,168.78,168.78,0,0,1,625,793.78ZM800.45,489a39.45,39.45,0,1,1,39.45-39.45A39.45,39.45,0,0,1,800.45,489Z"/>
		<path style={{fill:"#fff"}} d="M625,25.55C293.93,25.55,25.55,293.93,25.55,625h0c0,331.07,268.38,599.45,599.45,599.45S1224.45,956.07,1224.45,625h0C1224.45,293.93,956.07,25.55,625,25.55Zm326.71,735c-1.6,35-7.16,58.87-15.28,79.78a168,168,0,0,1-96.13,96.13c-20.91,8.12-44.8,13.68-79.78,15.28s-46.25,2-135.52,2-100.45-.38-135.51-2-58.88-7.16-79.79-15.28a168.07,168.07,0,0,1-96.12-96.13c-8.13-20.91-13.69-44.8-15.28-79.78s-2-46.25-2-135.52.37-100.45,2-135.51,7.15-58.87,15.28-79.78a168.07,168.07,0,0,1,96.12-96.13c20.91-8.12,44.8-13.68,79.79-15.28s46.25-2,135.51-2,100.45.39,135.52,2,58.87,7.16,79.78,15.28a168,168,0,0,1,96.13,96.13c8.12,20.91,13.68,44.79,15.28,79.78s2,46.25,2,135.51S953.3,725.46,951.71,760.52Z"/>
	</svg>

export const icon_fb_white =
	<svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1250 1250">
		<path style={{fill:"#fff"}} d="M625,25.55C293.93,25.55,25.55,293.93,25.55,625h0c0,331.07,268.38,599.45,599.45,599.45S1224.45,956.07,1224.45,625h0C1224.45,293.93,956.07,25.55,625,25.55ZM794.83,386H714.64c-28.33,0-34.21,11.58-34.21,40.87v70.76h114.4l-11,124.25H680.43V992.94H532.25V623.43H455.17V497.63h77.08V398.57c0-92.95,49.71-141.51,160-141.51h102.6Z"/>
	</svg>

export const icon_in_white =
	<svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1250 1250">
		<path style={{fill:"#fff"}} d="M625,25.55C293.94,25.55,25.55,293.93,25.55,625h0c0,331.07,268.39,599.45,599.45,599.45S1224.45,956.07,1224.45,625h0C1224.45,293.93,956.07,25.55,625,25.55ZM444.28,955.29H308V514.91H444.28Zm-68.81-498c-44.53,0-80.6-36.41-80.6-81.28s36.07-81.25,80.6-81.25S456.08,331.1,456.08,376,420,457.24,375.47,457.24ZM955.13,955.29H819.46V724.11c0-63.38-24.09-98.79-74.21-98.79-54.57,0-83.06,36.89-83.06,98.79V955.29H531.43V514.91H662.19v59.32s39.31-72.77,132.72-72.77,160.22,57,160.22,175Z"/>
	</svg>

export const icon_mail_white =
	<svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1250 1250">
		<path style={{fill:"#fff"}} d="M625,25.55C293.94,25.55,25.55,293.93,25.55,625h0c0,331.07,268.39,599.45,599.45,599.45S1224.45,956.07,1224.45,625h0C1224.45,293.93,956.07,25.55,625,25.55Zm443.72,836.18A94.35,94.35,0,0,1,974.48,956h-699a94.35,94.35,0,0,1-94.24-94.24V388.27A94.35,94.35,0,0,1,275.52,294h699a94.35,94.35,0,0,1,94.24,94.24Z"/>
		<path style={{fill:"#fff"}} d="M625,646.75,219.28,420.61V861.73A56.3,56.3,0,0,0,275.52,918h699a56.3,56.3,0,0,0,56.24-56.24V420.61Z"/>
		<path style={{fill:"#fff"}} d="M625,603.25l404.7-225.58A56.31,56.31,0,0,0,974.48,332h-699a56.31,56.31,0,0,0-55.22,45.64Z"/>
	</svg>
