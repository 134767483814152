import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setView, selectView } from "../store/home/homeSlice"
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import * as styles from "./smooth_scroll.module.css"


/*
 * On scroll, triggers a smooth transition between the home page and the bottom content.
 * @component
 */
const SmoothScroll = ({ homeContent, bottomContent }) => {
	const dispatch = useDispatch();
	const viewChange = useSelector(selectView);
	const { ref, inView } = useInView({
	  threshold: 0.4,
	  initialInView: !viewChange
	});

	let homeContentStyle = {
		display: !inView ? "none" : "block",
		top: 0,
		margin: "0 auto",
		width: "100%",
		height: "110vh"
	}

	let botContentStyle = {
		margin: "0 auto",
		display: "block",
	    width: "100%",
		transition: "transform 0.5s linear, opacity 1s ease-in-out",
		transform: !inView ? "translateY(0)" : "translateY(100%)",
		opacity: !inView ? 1 : 0,
	}

	useEffect( () => {
		if( typeof window !== undefined ) {
			if( !inView && !viewChange ) {
				dispatch(setView(true));
				window.scrollTo( 0, 0 );
			}
		}
	}, [viewChange, inView, dispatch])

	return (
		<>
			{ !viewChange && <div ref={ref} className={styles.waypoint}></div>}
			<div
				id="homeMain"
				style={homeContentStyle}
			>
				{homeContent}
			</div>

			<div style={botContentStyle}>
				{bottomContent}
			</div>
		</>
	)
}


SmoothScroll.propTypes = {
	/**
	 *	HTML node to be placed in the main section of the page.
	 */
	homeContent: PropTypes.node,
	/**
	 *	HTML node to be placed in the bottom section of the page.
	 */
	bottomContent: PropTypes.node,
}


export default SmoothScroll
