import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as styles from "./responsive_nav.module.css"
import * as screen from "../utils/mobile.module.css"
import ColoredSeparator from "./colored_sep"
import * as svgs from "../utils/svg_provider"


const homeWork_style_middle = [styles.item_padding, styles.fitem_center].join(" ");
const homeWork_style_right = [styles.item_padding, styles.fitem_right].join(" ");
const aboutSocial_style_middle = [styles.item_padding, styles.fitem_center].join(" ");

const HomeWork = <ul className={[styles.headWrapper, screen.hideMobile].join(" ")}>
					<li className={styles.item_padding}> <Link to="/"> <span className={styles.textStyleSide}>HOME</span> </Link> </li>
					<li className={homeWork_style_middle}> <Link to="/"> <span className={styles.textStyleMiddle}>Samantha Santoni</span> </Link></li>
					<li className={homeWork_style_right}> <Link to="/"> <span className={styles.textStyleSide}>WORK</span> </Link> </li>
				</ul>

const AboutSocial = <ul className={[styles.headWrapper, screen.hideMobile].join(" ")} >
						<li className={styles.item_padding}><Link to="/contacts" > <span className={styles.textStyleSide}>CONTACTS</span> </Link></li>
						<li className={aboutSocial_style_middle}> <Link to="/"> <span className={styles.textStyleMiddle}>Samantha Santoni</span> </Link></li>
						<li className={styles.fitem_right}>
							<ul className={styles.socialList}>
								<li><a href="https://www.instagram.com/2s_design/" target="_blank" rel="noopener noreferrer">{svgs.icon_ig_black}</a></li>
								<li><a href="https://www.facebook.com/SamanthaSantoniDesign" target="_blank" rel="noopener noreferrer">{svgs.icon_fb_black}</a></li>
								<li><a href="https://www.linkedin.com/in/samantha-santoni-839b3537/" target="_blank" rel="noopener noreferrer">{svgs.icon_in_black}</a></li>
								<li><a href="https://www.behance.net/SamanthaSantoni" target="_blank" rel="noopener noreferrer">{svgs.icon_be_black}</a></li>
								<li><a href="mailto:2sdesign.santoni@gmail.com" target="_blank" rel="noopener noreferrer">{svgs.icon_mail_black}</a></li>
							</ul>
						</li>
					</ul>

const MobileNav = ({hname, href, customWrapperCname}) => {
	const [toggle, setToggle] = React.useState(0)
	const viewStyle = toggle ? [styles.navMenu, styles.show].join(" ") : [styles.navMenu, styles.hide].join(" ")
	const hamView = toggle ? [styles.bar, styles.change].join(" ") : styles.bar

	return (
		<div className={[styles.mobileWrapper, customWrapperCname].join(" ")}>
			<Link to="/" className={styles.ss_icon}>Samantha Santoni</Link>

			<button className={styles.ham_container} onClick={() => {setToggle(!toggle)}} aria-label="Mobile Menu Button">
				<div>
					<div className={hamView}></div>
					<div className={hamView}></div>
					<div className={hamView}></div>
				</div>
			</button>

			<ul className={viewStyle}>
				<li> <Link to={href}> {hname} </Link> </li>
				<li><a href="https://www.instagram.com/2s_design/" target="_blank" rel="noopener noreferrer">{svgs.icon_ig_black}</a></li>
				<li><a href="https://www.facebook.com/SamanthaSantoniDesign" target="_blank" rel="noopener noreferrer">{svgs.icon_fb_black}</a></li>
				<li><a href="https://www.linkedin.com/in/samantha-santoni-839b3537/" target="_blank" rel="noopener noreferrer">{svgs.icon_in_black}</a></li>
				<li><a href="https://www.behance.net/SamanthaSantoni" target="_blank" rel="noopener noreferrer">{svgs.icon_be_black}</a></li>
				<li><a href="mailto:2sdesign.santoni@gmail.com" target="_blank" rel="noopener noreferrer">{svgs.icon_mail_black}</a></li>
			</ul>
		</div>
	)
}


/**
 * Nav menu used in desktop mode
 * @component
 */
const ResponsiveNav = ({hname, style}) => {
	return (
		<div style={{...style}}>
			{/* Getting the nav menu based on the given menuType. Check propTypes for a list of available types.
				Not giving a correct type, will cause a warning during compilation. */}
			<MobileNav hname={hname} href={hname === "Work" ? "/" : "/contacts"} customWrapperCname={screen.hideDesktop}/>
			{hname === "Work" ? HomeWork : AboutSocial}
			<ColoredSeparator color="black" customWrapperCname={screen.hideMobile}/>
		</div>
	)
}


ResponsiveNav.propTypes = {
	/*
	 * Custom style applied to the nav element
	 */
	customStyle: PropTypes.objectOf(PropTypes.string),
}

export default ResponsiveNav
