import React from "react"
import * as styles from "./jump_anim.module.css"


/**
 * Object with infinite jump animation
 */
const JumpAnim = ( {children, wrapper_custom_class} ) => (
	<div className={wrapper_custom_class + " " + styles.arrow_wrapper}>
		{children}
	</div>
)


export default JumpAnim
