import React from "react";
import * as styles from "./top_button.module.css";


const TopButton = () => {

	const goTop = () => {
		if( typeof window !== "undefined" ) {
			window.scrollTo(0, 0);
		}
	}

	return (
		<button className={styles.topButton} onClick={goTop}>
			<i className={styles.arrowTop}></i>
		</button>
	);
}

export default TopButton;
