import { Link } from "gatsby"
import React from "react"
import ColoredSeparator from "./colored_sep"
import * as styles from "./footer_home.module.css"
import * as svgs from "../utils/svg_provider"


// Footer component in the home page
const FooterHome = ({ customStyle }) => (
	<div className={[styles.footer_wrapper, customStyle].join(" ")}>
		<div className={styles.cellitem}>
			<ColoredSeparator color="white" />
		</div>

		<div className={styles.cellitem}>
			<Link to="/contacts/" className={styles.contacts_link}>
				CONTACTS
			</Link>
		</div>

		<div className={styles.cellitem}>
			<ul className={styles.socialist_wrapper}>
				<li><a href="https://www.instagram.com/2s_design/" target="_blank" rel="noopener noreferrer">{svgs.icon_ig_white}</a></li>
				<li><a href="https://www.facebook.com/SamanthaSantoniDesign" target="_blank" rel="noopener noreferrer">{svgs.icon_fb_white}</a></li>
				<li><a href="https://www.linkedin.com/in/samantha-santoni-839b3537/" target="_blank" rel="noopener noreferrer">{svgs.icon_in_white}</a></li>
				<li><a href="https://www.behance.net/SamanthaSantoni" target="_blank" rel="noopener noreferrer">{svgs.icon_be_white}</a></li>
				<li><a href="mailto:2sdesign.santoni@gmail.com" target="_blank" rel="noopener noreferrer">{svgs.icon_mail_white}</a></li>
			</ul>
		</div>
	</div>
)


export default FooterHome
