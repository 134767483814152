import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import FluidImage from "./fluid_image"
import * as styles from "./work_gallery.module.css"
import { useDispatch, useSelector } from 'react-redux'
import { setView, setWindowScrollY, selectWindowScrollY } from '../store/home/homeSlice.js'
import ColoredSeparator from "./colored_sep"


const WorkGallery = () => {
	const dispatch = useDispatch();
	const last_scrollY = useSelector(selectWindowScrollY);

	const data = useStaticQuery(graphql`
		query {
			allMdx( filter: {frontmatter: {path: {eq: "/content/works/"}}},
					sort: {fields: frontmatter___date})
			{
				edges {
					node {
						id
						fields {
							slug
						}
						frontmatter {
							title
							category
							altname
							thumbType
							thumbnail {
								ext
								publicURL
								...fluidImage
							}
						}
					}
				}
			}
		}
	`);

	const handleClick = () => {
		// Save last scroll position before going in work page.
		// The position will be restored as soon as we are going back to work gallery.
		if( typeof window !== undefined ) {
			dispatch(setWindowScrollY(window.scrollY));
		}
	}

	const handleBack = () => {
		// Resetting state if going back to Home page
		dispatch(setView(false));
		dispatch(setWindowScrollY(0));
	}

	useEffect( () => {
		if( typeof window !== undefined ) {
			window.scrollTo ( 0, last_scrollY );
		}
	}, [last_scrollY])

	return (
		// <div className={styles.gallery_wrapper}>
		<>
		<ul className={styles.grid}>
			{ data.allMdx.edges.map( ({ node }) =>
				<li className={styles.cellitem} key={node.id}>
					<Link to={`/work${node.fields.slug}`} className={styles.work_link} onClick={handleClick}>
						<div className =
							{
								( node.frontmatter.thumbnail.ext === ".mp4")
								? styles.thumb_wrapper
								: styles.thumb_wrapper + " " + styles.thumb_scale + " " + styles.fade_out
						 	}
						>
							{
								(node.frontmatter.thumbnail.ext === ".mp4")
								? ( node.frontmatter.thumbType === "normal" )
								?	<video className={styles.video_thumb} controls>
										<source src={node.frontmatter.thumbnail.publicURL} type="video/mp4"></source>
										Your Browser does not support video player feature.
									</video>
								:	<video className={styles.video_thumb} autoPlay loop muted>
									   <source src={node.frontmatter.thumbnail.publicURL} type="video/mp4"></source>
									   Your Browser does not support video player feature.
									</video>
								:	<>
									<FluidImage
										image={getImage(node.frontmatter.thumbnail)}
										altName={node.frontmatter.altname}
										wrapperCname={styles.gallery_img_container}
									/>
									<p className={styles.thumb_text_wrapper}>
										<span id="workGalTitle" className={styles.thumb_title}>
											{node.frontmatter.title}
										</span>
										<span id="workGalCategory" className={styles.thumb_category}>
											{node.frontmatter.category}
										</span>
									</p>
									</>
							}
						</div>
					</Link>
				</li>)
			}
		</ul>
		<div className={styles.backLink}>
			<Link to="/" style={{textDecoration:"none", color:"black"}} onClick={handleBack}>
				BACK TO HOME PAGE
			</Link>
			<ColoredSeparator color="black" />
		</div>
		</>

		// </div>
	)
}

export default WorkGallery

// Fragment used for query on jpg and png images
export const fluidImage = graphql`
fragment fluidImage on File {
	childImageSharp {
		gatsbyImageData (
			quality: 80
		)
	}
}`;
