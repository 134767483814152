// extracted by mini-css-extract-plugin
export var gallery_wrapper = "work_gallery-module--gallery_wrapper--aG3pE";
export var grid = "work_gallery-module--grid--3PiUU";
export var cellitem = "work_gallery-module--cellitem--3G3lh";
export var thumb_wrapper = "work_gallery-module--thumb_wrapper--3JqqF";
export var thumb_scale = "work_gallery-module--thumb_scale--1sXRl";
export var gallery_img_container = "work_gallery-module--gallery_img_container--17iT6";
export var fade_out = "work_gallery-module--fade_out--1bGjG";
export var work_link = "work_gallery-module--work_link--1BHEX";
export var thumb_text_wrapper = "work_gallery-module--thumb_text_wrapper--2_ojc";
export var thumb_title = "work_gallery-module--thumb_title--1FkSo";
export var thumb_category = "work_gallery-module--thumb_category--3ZhWR";
export var backLink = "work_gallery-module--backLink--1D2E0";
export var video_thumb = "work_gallery-module--video_thumb--3hPoJ";