import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "./index.module.css"
import * as screen from "../utils/mobile.module.css"
import JumpAnim from "../components/jump_anim"
import WorkGallery from "../components/work_gallery"
import SmoothScroll from "../components/smooth_scroll"
import FooterHome from "../components/footer_home"
import * as svgs from "../utils/svg_provider"
import ResponsiveNav from "../components/responsive_nav"
import RevealableComp from "../components/revealable_comp"
import TopButton from "../components/top_button"
import { selectView } from "../store/home/homeSlice"
import { useSelector } from "react-redux"



const topBtn = {
	position: "fixed",
	width: "40px",
	height: "40px",
	top: "90vh",
	left: "90vw",
	border: "2px solid black",
	borderRadius: "20px",
	background: "white",
	textDecoration: "none",
	cursor: "pointer"
}

/*
 * Site home content with smooth scrolling transaction.
 */
const IndexHome = () => {
	return	<div className={styles.homep_wrapper}>
				<h1 id="homeTitle" style={{color:"white", fontWeight:"normal"}}>Samantha Santoni</h1>

				<h2 className={styles.textMain_h3}>GRAPHIC DESIGNER</h2>

				<JumpAnim wrapper_custom_class={styles.arrowStyle}>
					{svgs.arrow_home}
				</JumpAnim>
			</div>
}


const IndexPage = () => {
	const viewChange = useSelector(selectView)

	return (
		<Layout
			nav = { viewChange &&
					<RevealableComp threshold={0.5} style={{width: "90vw", position:"fixed"}}>
				   		<ResponsiveNav hname="Contacts" style={{width: "100%", background:"white"}} />
				   	</RevealableComp>
				  }
		>
			{ viewChange && <RevealableComp threshold={1} style={topBtn}>
				<TopButton />
			</RevealableComp>}
			<Seo title="Home"/>
			<SmoothScroll
				homeContent={<><IndexHome/> <FooterHome customStyle={screen.hideMobile}/> </>}
				bottomContent={<WorkGallery />}
			/>
		</Layout>
	)
}

export default IndexPage
